.ProjectsGallery {
  height: 100%;
  padding: 0 2rem 0 2rem;

  .Project-Gallery-Header {
    display: flex;
    padding: 0 8px 0 0;
    margin: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .Left-Side {
      // margin: 1rem 0rem;
      .SubHeader {
        font-size: 20px;
        color: var(--black-60, #45464e);
        font-weight: 700;
      }

      .sub-SubHeader {
        font-size: 14px;
        color: var(--black-60, #45464e);
        font-weight: 500;
      }
    }

    .Right-Side {
      // margin-left: auto;
      // align-self: center;
    }
  }

  .thumb {
    //width: 2rem;
  }

  .project-card {
    .ant-card-body {
      height: 6rem;
    }
  }

  .ant-col {
    float: right;
    margin-bottom: 16px;
  }

  .new-project-card {
    height: 100%;
    padding-top: 4rem;

    .content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;

      .new-icon {
        font-size: 24px;
      }

      .new-project-card-meta {
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        .ant-card-meta-title {
          justify-content: center;
        }
        .ant-card-meta-description {
          color: #45464e;
          margin-top: 0;
        }
      }
    }
  }

  .ant-card {
    border-radius: 16px;

    .ant-card-meta {
      .ant-card-meta-title {
        font-weight: 600;
        margin-bottom: 0;
        display: flex;
        width: 100%;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        justify-content: space-between;
        .title,
        .account-id {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          min-width: 0;
          flex: 1;
          max-width: fit-content;
        }

        .account-id {
          margin-left: 10px;
          font-size: 14px;
          font-weight: 400;
          opacity: 0.45;
          line-height: var(--ant-line-height);
        }
      }

      .ant-card-meta-detail {
      }
    }

    .ant-card-cover {
      padding: 1rem 1rem 0 1rem;
    }

    .ant-card-cover img {
      //min-height: 220.5px;
      //max-height: 220.5px;
      border-radius: 12px;
      height: 10rem;
      background: #e1e2e9;
      object-fit: contain;
    }
  }
}
