.blings-modal {
  background-color: #f2f4f8;
}

.ant-modal-content {
  background-color: #f2f4f8 !important;
  border: 0 !important;
  box-shadow: 0 !important;
  border-radius: 12px !important;
  height: fit-content !important;
  max-height: calc(100vh - 40px) !important;

  .ant-modal-header {
    background-color: #f2f4f8 !important;
  }
}

.ant-modal-mask {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  overflow-y: hidden !important;
}

.ant-modal-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.ant-modal {
  top: 0 !important;
  padding: 0 !important;
  max-height: 100% !important;
  margin: 10px 0;
}
